import React from 'react';

export default function AuthFooter({ DEBUG, BASE_URL, isAuthenticated, currentUser, handleAuthenticationChange, getCSRF, displayLoginForm, clearMarkers, isResponseOk }) {

    const logout = () => {
        fetch(`${BASE_URL}/logout`, {
            credentials: "include",
        })
        .then(isResponseOk)
        .then((data) => {
            if (DEBUG) {
                console.log(data);
            }
            handleAuthenticationChange(false, "");
            getCSRF();
            displayLoginForm();
            clearMarkers();
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return(
        <div id="auth" className="diagonal mt-auto px-4 flex content-end cursor-default select-none">
            {isAuthenticated ? (
                <div className="diagonal-content pb-6 flex flex-row w-full justify-between mt-auto">
                    <div className="mt-2 text-teal-200 cursor-default">
                        Logged in as
                        <span className="font-semibold display:inline"> {currentUser}</span>
                    </div>
                    <button className="mt-2 text-teal-200 hover:text-white" onClick={logout}>Logout</button>
                </div>
            ) : (<div className="py-7"></div>)}
        </div>
    )
}
