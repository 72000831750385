import React from "react";

const InputField = ({
    label,
    id,
    type="text",
    value,
    onChange,
    onKeyDown,
    maxLength,
    placeholder="",
    readOnly=false,
    className="",
    labelClassName="",
    inputClassName="",
}) => {
    return (
        <div className={`${className}`}>
            {label && (
                <label
                    className={`block text-gray-700 text-lg leading-none font-extrabold mb-2 cursor-default select-none ${labelClassName}`}
                    htmlFor={id}
                >
                    {label}
                </label>
            )}
            <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 focus:outline outline-gray-200 outline-offset-2 outline-2 ${inputClassName}`}
                id={id}
                type={type}
                value={value}
                maxLength={maxLength}
                placeholder={placeholder}
                readOnly={readOnly}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
        </div>
    );
};

export default InputField;
