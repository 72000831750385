import { React, useState, useEffect, useRef } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import '../../node_modules/mapbox-gl/dist/mapbox-gl.css'
import Sidebar from "./Sidebar";

mapboxgl.accessToken = "pk.eyJ1Ijoiam9lZGF2aXNvbiIsImEiOiJjbGpyNnh1bGwwMWJpM2RtdWVudGtxdHIzIn0.xPIegcKezYE_Qh7ki276ug";

export default function Map ({
    map,
    lng,
    lat,
    handleLatChange,
    handleLngChange,
    toggleSidebar,
    handleSidebarWidthChange,
    setShowPageLoader
}) {
    const mapContainer = useRef(null);
    const [zoom, setZoom] = useState(4.8);
    const [appLoaded, setAppLoaded] = useState(false);

    useEffect(() => {
        if (map.current) return;
        if (window.innerWidth < 768) {
            document.getElementById('sidebar').classList.add('collapsed');
            document.getElementById('sidebar-toggle').classList.add('collapsed');
        }
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            projection: 'mercator',
            style: 'mapbox://styles/joedavison/clk17isgp00b401pf9fse6li6',
            center: [lng, lat],
            zoom: zoom,
            maxPitch: 0,
        });
        map.current.addControl(new mapboxgl.NavigationControl());
        handleSidebarWidthChange();
    });

    useEffect(() => {
        if (!map.current || !appLoaded) return;
        // Runs after map initialised
        map.current.on('move', () => {
            handleLngChange(map.current.getCenter().lng.toFixed(4));
            handleLatChange(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
        setTimeout(() => {
            setShowPageLoader(false);
        }, 500);
    });

    const toggleCrosshair = () => {
        document.getElementById('crosshair').classList.toggle('hidden');
        document.getElementById('crosshair-toggle').classList.toggle('toggle-active');
    }

    const toggleMarkers = () => {
        document.getElementById('markers-toggle').classList.toggle('toggle-active');
        const markers = document.getElementsByClassName('mapboxgl-marker');
        const popupContent = document.getElementsByClassName('mapboxgl-popup-content');
        const popupTip = document.getElementsByClassName('mapboxgl-popup-tip');
        for (let i = 0; i < markers.length; i++) {
            markers[i].classList.toggle('hidden');
        }
        for (let i = 0; i < popupContent.length; i++) {
            popupContent[i].classList.toggle('hidden');
        }
        for (let i = 0; i < popupTip.length; i++) {
            popupTip[i].classList.toggle('hidden');
        }
    }

    return (
        <div id="map" className="h-full w-full relative">
            <div ref={mapContainer} className="map-container h-full"/>
            <svg id="crosshair" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#454545" strokeWidth="0.75" strokeLinecap="square" strokeLinejoin="square" className="lucide lucide-plus"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
            <Sidebar toggleSidebar={toggleSidebar} lat={lat} lng={lng} map={map} setAppLoaded={setAppLoaded}/>
            <div id="custom-controls" className="controls flex flex-col">     
                <button className="control control-top" id="crosshair-toggle" type="button" aria-label="Toggle crosshair" aria-disabled="false" title="Toggle crosshair" onClick={toggleCrosshair}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24" fill="none" stroke="#454545" strokeWidth="1.25" strokeLinecap="square" strokeLinejoin="square"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
                </button>
                <button className="control control-bottom" id="markers-toggle" type="button" aria-label="Toggle markers" aria-disabled="false" title="Toggle markers" onClick={toggleMarkers}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="25" viewBox="0 0 24 24" fill="#3fb1ce" stroke="#348fa7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"/><circle stroke="white" fill="white" cx="12" cy="10" r="3"/></svg>
                </button>
            </div>
        </div>
    )
}
