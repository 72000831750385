import { React, useState } from 'react';
import { ButtonTeal } from "../Buttons"
import InputField from "../Input";
import { useToast } from "../shadcn/use-toast.tsx"

export default function RegisterForm({ DEBUG, BASE_URL, csrf, handleAuthenticationChange, getCSRF, isResponseOk, onLoginClick, toggleSidebar }) {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [error, setError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [password2Error, setPassword2Error] = useState("");

    const { toast } = useToast();

    const register = (event) =>{
        setError("");
        if (DEBUG) {
            console.log("Registering user: " + username);
            console.log(csrf);
        }
        event.preventDefault();
        if (username === "" || email === "" || password === "" || password2 === "") {
            setError("Please fill in all fields.");
            return;
        }
        if (password !== password2) {
            setError("Passwords do not match.");
            return;
        }
        fetch(`${BASE_URL}/register/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
            body: JSON.stringify({ username, email, password, password2 }),
        })
        .then(registerResponse)
        .catch((err) => {
            setError(err);
            console.log(err);
        });
    };

    const registerResponse = (response) => {
        if (response.status >= 200 && response.status <= 299) {
            if (DEBUG) {
                console.log("Registration successful");
            }
            return response.json().then((data) => {
                if (DEBUG) {
                    console.log(data);
                }
                fetch(`${BASE_URL}/login/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf,
                    },
                    credentials: "include",
                        body: JSON.stringify({username: username, password: password}),
                    })
                    .then(isResponseOk)
                    .then((data) => {
                        if (DEBUG) {
                            console.log(data);
                        }
                        handleAuthenticationChange(true, username);
                        clearState();
                        if (window.innerWidth < 768) {
                            toggleSidebar();
                        }
                        getCSRF();
                        toast({
                            title: `Successfully signed up as ${data.currentUser}.`,
                        });
                    })
                });
        } else {
            if (DEBUG) {
                console.log("Registration failed");
            }
            return response.json().then((data) => {
                if (DEBUG) {
                    console.log(data);
                }
                if (data.username) {
                    if (data.username[0] !== "This field may not be blank.") {
                        setUsernameError(data.username[0]);
                    }
                }
                if (data.email) {
                    if (data.email[0] !== "This field may not be blank.") {
                        setEmailError(data.email[0]);
                    }
                    if (data.email[0] === "This field must be unique.") {
                        setEmailError("A user with that email already exists.");
                    }
                }
                if (data.password) {
                    if (data.password[0] !== "This field may not be blank.") {
                        setPasswordError(data.password[0]);
                    }
                }
                if (data.password2) {
                    if (data.password2[0] !== "This field may not be blank.") {
                        setPassword2Error(data.password2[0]);
                    }
                }
            });
        }
    }
    
    const handleUsernameChange = (event) => {
        setUsernameError("");
        setUsername(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmailError("");
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPasswordError("");
        setPassword(event.target.value);
    }

    const handlePassword2Change = (event) => {
        setPassword2Error("");
        setPassword2(event.target.value);
    }

    const handleLoginClick = () => {
        clearState();
        onLoginClick();
    }

    const clearState = () => {
        setUsername("");
        setEmail("");
        setPassword("");
        setPassword2("");
        setUsernameError("");
        setEmailError("");
        setPasswordError("");
        setPassword2Error("");
        setError("");
    }

    return (
        <div id="register-form">
            <div className="mb-4 text-center block text-2xl text-teal-900 font-extrabold cursor-default select-none">Sign Up</div>
            <form className="flex flex-col" onSubmit={register}>
                <InputField
                    label="Username"
                    id="username"
                    value={username}
                    maxLength="18"
                    onChange={handleUsernameChange}
                    className={!usernameError && "mb-3"}
                />
                {usernameError && <div className="mb-3 text-center font-semibold text-red-500 text-lg leading-none">{usernameError}</div>}


                <InputField
                    label="Email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    className={!emailError && "mb-3"}
                />
                {emailError && <div className="mb-3 text-center font-semibold text-red-500 text-lg leading-none">{emailError}</div>}

                <InputField
                    label="Password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    type="password"
                    className={!passwordError && "mb-3"}
                />
                {passwordError && <div className="mb-3 text-center font-semibold text-red-500 text-lg leading-none">{passwordError}</div>}

                <InputField
                    label="Confirm Password"
                    id="password2"
                    value={password2}
                    onChange={handlePassword2Change}
                    type="password"
                    className={!password2Error && "mb-3"}
                />
                {password2Error && <div className="mb-3 text-center font-semibold text-red-500 text-lg leading-none">{password2Error}</div>}

                {error && <div className="mb-4 text-center font-semibold text-red-500 text-lg leading-none">{error}</div>}
                <ButtonTeal outerClass="mx-auto w-28 text-lg leading-5" buttonText="Sign Up" buttonType="submit" />
            </form>
            <div className="pt-4 text-center text-gray-700 text-lg leading-none font-extrabold cursor-default select-none">
                Already have an account?
                <button className="text-lg leading-none ps-1 underline" onClick={handleLoginClick}>Log In</button>
            </div>
        </div>
    );
}