import { React, useState, useEffect } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useToast } from "./shadcn/use-toast.tsx"

import AuthForms from "./Auth/AuthForms";
import AuthFooter from "./Auth/AuthFooter";
import PinForm from "./Pin/PinForm";
import PinEditForm from "./Pin/PinEditForm";
import PinDetail from "./Pin/PinDetail";
import SidebarCollapsed from "./Logo/SidebarCollapsed";
import SidebarDesktopLogo from "./Logo/SidebarDesktopLogo";
import { CompassLoader } from "./Loaders";

export default function Sidebar({ toggleSidebar, lat, lng, map, setAppLoaded }) {
    const [showLoginForm, setShowLoginForm] = useState(true);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [activeElement, setActiveElement] = useState("PinForm");
    const [markers, setMarkers] = useState([]);
    const [showPinDelete, setShowPinDelete] = useState(false);
    const [pinInstance, setPinInstance] = useState({geometry: {coordinates: []}, properties: {pk: 0, name: "", description: "", tags: []}});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState("");
    const [csrf, setCsrf] = useState("");
    const [showSidebarLoader, setShowSidebarLoader] = useState(false);

    const BASE_URL = process.env.REACT_APP_API_URL;
    const DEBUG = (process.env.REACT_APP_DEBUG === "true");

    const { toast } = useToast()

    useEffect(() => {
       healthCheck(1);
    }, []);

    function healthCheck(attempts) {
        if (DEBUG) {
            console.log(`Sending health check API call, attempts: ${attempts}`);
        }

        fetch(`${BASE_URL}/health/`)
        .then((res) => {
            if (DEBUG) {
                console.log("API health check successful");
            }
            if (res.status == 200) {
                clearInterval();
                getSession();
                getCSRF();
            }
        }).catch((err) => {
            console.log(err);

            if (attempts < 6) {
                setTimeout(() => {
                    healthCheck(attempts + 1);
                }, 10000);
            } else {
                document.getElementById("loader-tagline").innerText =
                "The website appears to be down, please try again later..."
            }

            if (DEBUG) {
                console.log("API health check failed");
            }
        });
    }

    const getCSRF = () => {
        if (DEBUG) {
            console.log("Getting CSRF token");
        }
        fetch(`${BASE_URL}/csrf/`, {
            credentials: "include",
        })
        .then((res) => {
            let csrfToken = res.headers.get("X-CSRFToken");
            setCsrf(csrfToken);
            if (DEBUG) {
                console.log('csrfToken: ' + csrfToken);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const getSession = () => {
        if (DEBUG) {
            console.log("Getting session");
        }
        fetch(`${BASE_URL}/session/`, {
            credentials: "include",
        })
        .then((res) => res.json())
        .then((data) => {
            if (DEBUG) {
                console.log(data);
            }
            if (data.isAuthenticated) {
                if (DEBUG) {
                    console.log("Authenticated");
                }
                setIsAuthenticated(true);
                setCurrentUser(data.currentUser);
                getPins(data.currentUser);
            } else {
                if (DEBUG) {
                    console.log("Not authenticated");
                }
                setIsAuthenticated(false);
                setCurrentUser("");
            }
            setAppLoaded(true);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const isResponseOk = (response) => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            if (DEBUG) {
                console.log(response.json());
                console.log(response);
            }
            throw Error(response.status + ' ' + response.statusText);
        }
    }

    const displayLoginForm = () => {
        setShowLoginForm(true);
        setShowRegisterForm(false);
    }

    const displayRegisterForm = () => {
        setShowLoginForm(false);
        setShowRegisterForm(true);
    }

    const handleAuthenticationChange = (isAuthenticated, username) => {
        setIsAuthenticated(isAuthenticated);
        setCurrentUser(username);
    }

    const getPins = (user) => {
        if (!user && !currentUser){
            return;
        }
        if (currentUser) {
            user = currentUser;
            if (DEBUG) {
                console.log("Getting pins for: " + currentUser);
            }
        }
        if (DEBUG) {
            console.log("Getting pins for: " + user);
        }
        fetch(`${BASE_URL}/pins/?created_by=${user}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
        })
        .then(isResponseOk)
        .then((data) => {
            data.features.map((feature) => {
                addMarker(feature);
            });
        })
        .catch(error => {
            console.error('Error during getPins:', error);
        });
    }

    const addMarker = (feature) => {
        const popup = new mapboxgl.Popup({ offset: 25 })
            .setHTML(
                `
                <div class="flex flex-col">
                    <h3 class="text-lg leading-none text-teal-800 font-extrabold p-2 pb-1 pe-6">
                        ${feature.properties.name}
                    </h3>
                    <p class="text-sm font-semibold text-gray-700 p-2 pt-0">
                        ${feature.properties.description}
                    </p>
                </div>
                `
            )
            .on('open', () => {
                displayPinDetail();
                setPinInstance(feature);
                if (DEBUG) {
                    console.log(feature);
                }
            })
            .on('close', () => {
                displayPinForm();
                clearPinInstance();
            });
        const marker = new mapboxgl.Marker({})
            .setLngLat(feature.geometry.coordinates)
            .setPopup(popup)
            .addTo(map.current);
        var pk = feature.properties.pk;
        const markerObject = {pk: pk, marker: marker};
        setMarkers(markers => [...markers, markerObject]);
    }

    const clearMarkers = () => {
        if (DEBUG) {
            console.log("Clearing markers");
        }
        if (markers!==null) {
            for (var i = markers.length - 1; i >= 0; i--) {
              markers[i].marker.remove();
            }
        }
        setMarkers([]);
    }

    const displayPinForm = () => {setActiveElement("PinForm");}
    const displayPinEditForm = () => {setActiveElement("PinEditForm");}
    const displayPinDetail = () => {setActiveElement("PinDetail");}

    const clearPinInstance = () => {
        setPinInstance({geometry: {coordinates: []}, properties: {pk: 0, name: "", description: "", tags: []}});
    }

    const PinContent = (
        <>
            {activeElement === "PinForm" &&
                <PinForm
                    DEBUG={DEBUG}
                    BASE_URL={BASE_URL}
                    lat={lat}
                    lng={lng}
                    csrf={csrf}
                    getPins={getPins}
                    isResponseOk={isResponseOk}
                    currentUser={currentUser}
                    toggleSidebar={toggleSidebar}
                />
            }
            {activeElement === "PinEditForm" &&
                <PinEditForm
                    DEBUG={DEBUG}
                    BASE_URL={BASE_URL}
                    csrf={csrf}
                    pin={pinInstance}
                    markers={markers}
                    clearMarkers={clearMarkers}
                    getPins={getPins}
                    isResponseOk={isResponseOk}
                    currentUser={currentUser}
                    closeForm={displayPinDetail}
                    toggleSidebar={toggleSidebar}
                />
            }
            {activeElement === "PinDetail" &&
                <PinDetail
                    DEBUG={DEBUG}
                    BASE_URL={BASE_URL}
                    csrf={csrf}
                    pin={pinInstance}
                    markers={markers}
                    toggleDeleteConfirmation={() => {setShowPinDelete(!showPinDelete);}}
                    showDelete={showPinDelete}
                    displayPinForm={displayPinForm}
                    displayPinEditForm={displayPinEditForm}
                    clearPinInstance={clearPinInstance}
                    toggleSidebar={toggleSidebar}
                />
            }
        </>
    )

    return (
        <div id="sidebar" className="w-full md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 flex flex-row">
            <SidebarCollapsed toggleSidebar={toggleSidebar}/>
            <div id="sidebar-content" className="flex flex-col h-full w-full bg-gradient-to-br from-green-300 via-emerald-200 to-emerald-300">
                <SidebarDesktopLogo/>
                <div id="sidebar-inner" className="flex flex-col h-full overflow-y-auto">
                    {showSidebarLoader ? (
                        <CompassLoader/>
                    ) : (
                        !isAuthenticated ? (
                            <AuthForms
                                DEBUG={DEBUG}
                                BASE_URL={BASE_URL}
                                csrf={csrf}
                                handleAuthenticationChange={handleAuthenticationChange}
                                displayLoginForm={displayLoginForm}
                                displayRegisterForm={displayRegisterForm}
                                showLoginForm={showLoginForm}
                                showRegisterForm={showRegisterForm}
                                getPins={getPins}
                                getCSRF={getCSRF}
                                isResponseOk={isResponseOk}
                                toggleSidebar={toggleSidebar}
                            />
                        ) : (
                            PinContent
                        )
                    )}                 
                </div>
                <AuthFooter
                    DEBUG={DEBUG}
                    BASE_URL={BASE_URL}
                    isAuthenticated={isAuthenticated}
                    currentUser={currentUser}
                    handleAuthenticationChange={handleAuthenticationChange}
                    getCSRF={getCSRF}
                    displayLoginForm={displayLoginForm}
                    clearMarkers={clearMarkers}
                    isResponseOk={isResponseOk}
                />
                <div id="sidebar-toggle" className="bg-teal-800 py-2 text-gray-100 hidden md:block" onClick={toggleSidebar}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-4 h-4"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" /></svg>
                </div>
            </div>
        </div>
    )
}